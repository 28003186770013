import styles from '../style/Apresentacao.module.css'

function Apresentacao(){
    return (
        <div className={styles.apresentacao}>
            <div>
                <h1 className={styles.h1Apresentacao}>Sua Consultoria em Gestão e Co-Criação de Valor</h1>
                
            </div>
        </div>)
}

export default Apresentacao
