import { Link } from 'react-router-dom';
import breadcrumb from '../style/breadcrumb.module.css';
import Style from '../style/Contato.module.css';
import { useState } from 'react';




function Form() {
  const [formData, setFormData] = useState({
    name: '',
    tel: '',
    email: '',
    empresa: '',
    cargo: '',
    mensagem: '',
  });

  const [enviado, setEnviado] = useState(false);

  const handleFormEdit = (event, name) => {
    setFormData({
      ...formData,
      [name]: event.target.value
    });
  };

  const handleForm = async (event) => {
    try {
      event.preventDefault();
      const response = await fetch(`/enviar_formulario.php`, {
        method: 'POST',
        body: new URLSearchParams(formData)
      });
      if (response.ok) {
        setEnviado(true); // Define enviado como true se a resposta for bem-sucedida
      } else {
        console.error('Erro ao enviar formulário:', response.statusText);
      }
    } catch (err) {
      console.error('Erro ao enviar formulário:', err);
    }
  };

  return (
    <>
      <nav className={breadcrumb.nav}>
        <ul>
          <Link to="/">
            <li>Home</li>
          </Link>
          <li>/</li>
          <li>Contate-nos</li>
        </ul>
      </nav>

      <div className={Style.container}>
        {enviado ? ( // Se enviado for true, exibe a mensagem de sucesso
          <div className={Style.successMessage}>
          
            <h2>Sua mensagem foi enviada, em breve retornaremos o contato.</h2>
            <h3>Atenciosamente</h3>
            <h3>Value Ware</h3>

          
            
          </div>
        ) : (
          <form className={Style.contato} id='contatenos' onSubmit={handleForm}>
            <h1 style={{ fontWeight: '100' }}>Contate-nos</h1>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputName">Nome*</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  name="name"
                  value={formData.name}
                  onChange={(e) => { handleFormEdit(e, 'name') }}
                  placeholder="Seu nome aqui"
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputTel">Telefone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="inputTel"
                  name="tel"
                  value={formData.tel}
                  onChange={(e) => { handleFormEdit(e, 'tel') }}
                  placeholder="(00) 9 0000-0000"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputEmail">E-mail*</label>
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                name="email"
                value={formData.email}
                onChange={(e) => { handleFormEdit(e, 'email') }}
                placeholder="exemplo@exemplo.com"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCompany">Empresa</label>
              <input
                type="text"
                className="form-control"
                id="inputCompany"
                name="empresa"
                value={formData.empresa}
                onChange={(e) => { handleFormEdit(e, 'empresa') }}
                placeholder="Nome da empresa"
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputOffice">Cargo</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputOffice"
                  name="cargo"
                  value={formData.cargo}
                  onChange={(e) => { handleFormEdit(e, 'cargo') }}
                  placeholder="Exemplo: Gerente"
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputTextArea">Mensagem</label>
                <textarea
                  className="form-control"
                  id="inputTextArea"
                  name="mensagem"
                  value={formData.mensagem}
                  onChange={(e) => { handleFormEdit(e, 'mensagem') }}
                  rows="4"
                  cols="250"
                ></textarea>
              </div>
            </div>
            <button type="submit">Enviar</button>
          </form>
            

        )}
      </div>
      
    </>
  );
}

export default Form;
